import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    popperContentThankYou: {
        padding: '1rem',
        textAlign: 'center',
        '@media (max-width: 960px)': {
            padding: '1rem 0.5rem',
        },
        '& > h3': {
            color: '#76b43f',
            marginTop: 0,
        },
    },
}));

const SubmitSuccess: React.FC<{}> = () => {
	const classes = useStyles();
	return (
	<>
	    <div className={classes.popperContentThankYou}>
	        <img
	            src='https://res.cloudinary.com/agiliti/image/upload/v1640248791/thank_you_icon.gif'
	            alt='Thank You'
	            width='152px'
	            height='128px'
	            title='Thank You, submitted successfully!'
	        />
	        <h3>Thanks for joining! <br/>Savers are few! You are wise!</h3>
	    </div>
	</>
	);
};

export default SubmitSuccess;