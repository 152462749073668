import React, {useEffect} from 'react';
import {graphql, Link} from 'gatsby';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Layout from '../components/layout';
import LeadCaptureForm from '../components/LeadCapture';
import Disclaimer from '../components/Disclaimer';
import Footer from '../components/Footer';
import SEO from '../components/seo';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import {useCookies} from "react-cookie";
import { isBrowser } from "browser-or-node";


interface Props {
    data: any
}

const useStyles = makeStyles(() => ({
    banner: {
        maxWidth: 'none!important',
        padding: '0!important',
        '& > section': {
            position: 'relative',
            textAlign: 'center',
            padding: '0rem 3rem',
            '@media (max-width:424px)': {
                padding: '0rem 1rem',
            },
        },
        '& > section > div': {
            zIndex: '-1',
            background: '#0a66c2',
            animation: 'fadeIn20 .75s ease-in-out',
            backgroundSize: 'cover',
            left: '0',
            position: 'absolute',
            right: '0',
            top: '0',
            height: '100%',
        },
    },
    pageHeading: {
        fontSize: '2rem',
        '@media (max-width:768px)': {
            fontSize: '1.5rem',
            padding: '0rem .1rem',
        },
    },
    lastLink: {
        display: 'none',
    },
    button: {
        color: '#0a66c2!important',
        backgroundColor: 'white!important',
        fontWeight: 'bold!important',
        boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        border: 'none!important',
        fontFamily: 'Lato!important',
        padding: '0!important',
        margin: '1rem 1rem 2rem!important',
        '&:hover': {
            background: 'white',
            boxShadow: '0 4px 6px 0 rgba(0,0,0,.45)',
        },
    },
    buttonLink: {
        display: 'flex',
        width: '6rem',
        justifyContent: 'center',
        textDecoration: 'none!important',
        padding: '0.4rem 0.3rem 0.3rem',
    },
    contentSection: {
        display: 'flex',
        backgroundColor: '#f2f8fe',
        '@media (max-width:960px)': {
            display: 'block',
        },
    },
    dateSection: {
        fontFamily: 'Lato!important',
        fontSize: '1.1rem!important',
        padding: '2rem 0rem 0rem',
    },
    getCompsButtonFloat: {
        top: '6rem',
        right: '1.5rem',
        zIndex: '10',
        background: '#2d87e3',
        color: '#fff',
        textTransform: 'capitalize',
        display: 'block',
        padding: '0rem 1rem',
        height: '3rem',
        fontWeight: 'bold',
        '@media (max-width:960px)': {
            right: '1rem',
        },
        '@media (max-width:375px)': {
            top: '5.5rem',
            height: '2rem',
        },
        '@media (min-width:2500px)': {
            fontSize: '1.5rem',
        },
        '&:hover': {
            background: '#0A66C2',
            color: '#fff',
        },
    },
}));

const google_client_id:string = process.env.GATSBY_GOOGLE_CLIENT_ID!;
const SQD_APP_URL = `${process.env.GATSBY_SQUAREDEAL_APP_URL}`;

const Template: React.FC<Props> = ({data}) => {
    const [cookie, setCookie] = useCookies();
    const {markdownRemark} = data;
    const {frontmatter, html} = markdownRemark;
    const classes = useStyles();

    if (isBrowser) {
        useGoogleOneTapLogin({
            disabled: (cookie.google_one_tap === 'true'),
            onError: (error) => console.log(error),
            onSuccess: (response) => {
                // we don't set cookie expiry time, since we just need the email id once.
                setCookie('google_one_tap', true);
                const api_url = process.env.GATSBY_API_SITE_LINK + '/lead/';
                const res = fetch(api_url, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        first_name: response.given_name,
                        last_name: response.family_name,
                        email_id: response.email,
                        source: 'BLOG_VISITOR_GOOGLE_ONE_TAP',
                    })
                });
            },
            googleAccountConfigs: {
                client_id: google_client_id,
                auto_select: true,
            },
        });
    }

    return (
        <Layout>
            <SEO title={frontmatter.title} description={frontmatter.description} />
            <Container className={classes.banner}>
                <section>
                    <Typography variant="caption" component="h5" className={classes.dateSection}>
                        {frontmatter.date}
                    </Typography>
                    <h1 className={classes.pageHeading}>{frontmatter.title}</h1>
                    <Button type='submit' id='form-submit' className={classes.button}>
                        <Link to="/" className={classes.buttonLink}><KeyboardBackspaceIcon/>&nbsp; Blogs</Link>
                    </Button>
                </section>
                <div dangerouslySetInnerHTML={{__html: html}} className={classes.contentSection}/>
            </Container>
            {/* Float button - To Home page */}
            <a href={SQD_APP_URL + '/'}>
                <Button variant="contained" className={classes.getCompsButtonFloat} sx={{position: 'absolute'}}>Check your Reduction - FREE!</Button>
            </a>
            <LeadCaptureForm/>
            <Disclaimer/>
            <Footer/>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
      }
    }
  }
`

export default Template
